import { ReactElement } from 'react';
import cn from 'classnames';

import { Typography } from '1_shared/ui';

import { DurationType } from '../../../1_shared/config/interfaces/DurationType';
import { timeConfig } from '../../../1_shared/config/timeConfig';
import { declination, yearTitles } from '../../../1_shared/helpers/declination';

import { ISpecInfoProps } from './interfaces/ISpecInfoProps';

import styles from './InfoSpecialist.module.scss';

const InfoSpecialist = ({
  specialist,
  classname = '',
  value,
  isPopover = false,
  dark = false,
}: ISpecInfoProps): ReactElement => (
    <div className={styles.infoSpec}>
      <div className={cn(styles.defaultInfo, { [classname]: classname !== '' })}>
        {!isPopover && (
          <div className={styles.infoSpecBloc}>
            <Typography type="description">ОПЫТ</Typography>
            <Typography className={cn({ [styles.darkText]: dark })}>
              {`${specialist.experience} ${declination(Number(specialist.experience), yearTitles)}`}
            </Typography>
          </div>
        )}
        <div className={styles.infoSpecBloc}>
          <Typography type="description">СЕССИЯ</Typography>
          <Typography className={cn({ [styles.darkText]: dark })}>
            {(value?.duration
              ? timeConfig[value.duration as DurationType]
              : timeConfig[specialist?.slots[0]?.duration as DurationType]) || 50}
          </Typography>
        </div>
        <div className={styles.infoSpecBloc}>
          <Typography type="description">СТОИМОСТЬ</Typography>
          <Typography
            className={cn(styles.titleInfo, { [styles.darkText]: dark })}
          >
            {`${value?.cost ? value.cost : specialist.sessionsInfo[0].price} ₽`}
          </Typography>
        </div>
      </div>
    </div>
  )

export default InfoSpecialist;
