import { ReactElement } from 'react';

import { ISpecialistListFilters } from '../../../../1_shared/config/interfaces';
import {
  experienceOptions,
} from '../../../../1_shared/constants/filterEnums';
import FilterSelect from '../FilterSelect/FilterSelect';

const SelectBlock = ({
  handleFormSubmit,
}: {
  handleFormSubmit: (data: ISpecialistListFilters) => void;
}): ReactElement => (
  <>
    {/* <FilterSelect */}
    {/*  name="specShowType" */}
    {/*  options={availableOptions} */}
    {/*  defaultValue="SHOW_ONLY_WITH_SLOTS" */}
    {/*  handleFormSubmit={handleFormSubmit} */}
    {/* /> */}
    <FilterSelect
      name="experienceSortOrder"
      options={experienceOptions}
      defaultValue="DESC"
      handleFormSubmit={handleFormSubmit}
    />
  </>
);

export default SelectBlock;
