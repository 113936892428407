import cn from 'classnames';

import { Typography } from '1_shared/ui';

import { ISpecSpecializtionProps } from './interfaces/ISpecSpecializtionProps';

import styles from './SpecSpecialization.module.scss';

const SpecSpecialization = ({
  specialization,
  className = '',
  dark = false,
}: ISpecSpecializtionProps) => {
  const spec = specialization.filter(el => !!el).join(', ');

  return (
    <div className={cn(styles.root, { [className]: className !== '' })}>
      <Typography type="description">СПЕЦИАЛИЗАЦИЯ</Typography>
      <div>
        <div>{!spec.length ? 'Не указана' : spec}</div>
      </div>
    </div>
  );
};

export default SpecSpecialization;
