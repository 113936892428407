import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex } from 'antd';

import Filters from '1_shared/assets/pictures/Filters.svg';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { Button, Typography } from '1_shared/ui';
import { FiltersForm } from '3_features';

import { ISpecialistFiltersProps } from './interfaces/ISpecialistFiltersProps';
import SelectBlock from './SelectBlock/SelectBlock';

import styles from './SpecialistFilters.module.scss';

const SpecialistFilters: FC<ISpecialistFiltersProps> = ({ onChange }) => {
  const { handleSubmit } = useFormContext<ISpecialistListFilters>();
  const [open, setOpen] = useState<boolean>(false);
  const filtersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (filtersRef.current) {
      filtersRef.current.style.height = open
        ? `${filtersRef.current.scrollHeight}px`
        : '0';
    }
  }, [open]);

  const handleFormSubmit = (data: ISpecialistListFilters) => {
    const filters = {
      filter: { ...data },
    };
    onChange(filters);
  };

  const handleFieldChange = () => {
    handleSubmit(handleFormSubmit)();
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className={styles.formContainer}
    >
      <div className={styles.filtersContainer}>
        <FiltersForm handleFieldChange={handleFieldChange} />
        <Flex justify="flex-end">
          <SelectBlock handleFormSubmit={handleFormSubmit} />
        </Flex>
      </div>
      <div className={styles.mobileFiltersContainer}>
        <Button
          type="secondary"
          className={styles.btnFilters}
          onClick={() => setOpen(!open)}
        >
          <img src={Filters} alt="filter" />
          <Typography>Фильтры</Typography>
        </Button>
        <div
          className={`${styles.mobileFilters} ${open ? 'open' : ''}`}
          ref={filtersRef}
        >
          <FiltersForm handleFieldChange={handleFieldChange} />
        </div>
        <SelectBlock handleFormSubmit={handleFormSubmit} />
      </div>
    </form>
  );
};

export default SpecialistFilters;
