import { z } from 'zod';

export const IMainInfoSchema = z.object({
  work_since: z.string().min(1, {
    message: 'Укажите год начала консультирования',
  }),
  about_yourself: z.string(),
  specialities: z
    .array(z.string())
    .min(1, { message: 'Должен быть выбран как минимум 1 пункт' }),
  work_methods: z
    .array(z.string())
    .min(1, { message: 'Должен быть выбран как минимум 1 пункт' }),
  key_themes: z
    .array(z.string())
    .min(1, { message: 'Должен быть выбран как минимум 1 пункт' }),
  work_withs: z
    .array(z.string())
    .min(1, { message: 'Должен быть выбран как минимум 1 пункт' }),
});

export type IMainInfo = z.infer<typeof IMainInfoSchema>;

export interface IMainInfoOlbBackObject {
  work_since?: string;
  about_yourself?: string;
  // TODO: старый бэкенд принимает на вход вместо массива join-строку для парсинга и возвращает []
  specialitiesIds: (string | undefined)[];
  workMethodsIds: (string | undefined)[];
  keyThemesIds: (string | undefined)[];
  workWithsIds: (string | undefined)[];
}
