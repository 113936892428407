import { ReactElement } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import TextArea from 'antd/es/input/TextArea';
import useMessage from 'antd/es/message/useMessage';
import useSWRMutation from 'swr/mutation';

import filterOption from '../../../../1_shared/helpers/filterOption';
import { Button, Input, Select, Typography } from '../../../../1_shared/ui';
import { CustomTypography } from '../../../../1_shared/ui/CustomTypography';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { userObjectConverterMainInfo } from '../../helpers/userObjectConverter';
import { editProfileMainInfo } from '../../model/service/specEditService';
import { mergeObjects } from '../OtherInformation/OtherInformation';

import { IMainInfo, IMainInfoSchema } from './interfaces/IMainInfo';
import useSpecialistEditProfileMainInfo from './model/useSpecialistEditProfileMainInfo';

import styles from '../Credentials/SpecialistEditProfileCredentials.module.scss';

const SpecialistEditProfileMainInfo = (): ReactElement => {
  const { setSpecUser } = useAuthContext();
  const user = JSON.parse(String(localStorage.getItem('user')));
  const [messageApi, contextHolder] = useMessage();

  const methods = useForm<IMainInfo>({
    resolver: zodResolver(IMainInfoSchema),
    defaultValues: userObjectConverterMainInfo(user),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { keyThemasMemo, workWithMemo, workMethodsMemo, specMemo } =
    useSpecialistEditProfileMainInfo();

  const { trigger, isMutating, error } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileMainInfo,
  );

  const onSubmit = async (data: IMainInfo) => {
    await trigger({
      work_since: data?.work_since,
      about_yourself: data?.about_yourself,
      specialitiesIds: data?.specialities || [],
      workMethodsIds: data?.work_methods || [],
      keyThemesIds: data?.key_themes || [],
      workWithsIds: data?.work_withs || [],
    });

    if (data && !error && setSpecUser) {
      setSpecUser(mergeObjects(user, data));
      return messageApi.open({
        type: 'success',
        content: 'Данные профиля отредактированы!',
      });
    }

    return messageApi.open({
      type: 'warning',
      content: 'Сохранить изменения не удалось!',
    });
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <Typography type="title">Основная информация</Typography>
        <div className={styles.row}>
          <Typography type="description">
            Опыт консультирования с (год)
            <span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="work_since"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                status={errors.work_since ? 'error' : undefined}
              />
            )}
          />
          {errors?.work_since && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.work_since?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Специализация<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="specialities"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                mode="multiple"
                options={specMemo}
                showSearch
                filterOption={filterOption}
                status={errors.specialities ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('specialities', value)}
                onClear={() => methods.setValue('specialities', [])}
              />
            )}
          />
          {errors?.specialities && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.specialities?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Работаете по методам
            <span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="work_methods"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                maxTagCount="responsive"
                mode="multiple"
                options={workMethodsMemo}
                showSearch
                filterOption={filterOption}
                status={errors.work_methods ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('work_methods', value)}
                onClear={() => methods.setValue('work_methods', [])}
              />
            )}
          />
          {errors?.work_methods && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.work_methods?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Ключевые темы<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="key_themes"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                mode="multiple"
                options={keyThemasMemo}
                showSearch
                filterOption={filterOption}
                status={errors.key_themes ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('key_themes', value)}
                onClear={() => methods.setValue('key_themes', [])}
              />
            )}
          />
          {errors?.key_themes && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.key_themes?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Работаете с<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="work_withs"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                mode="multiple"
                options={workWithMemo}
                showSearch
                filterOption={filterOption}
                status={errors.work_withs ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('work_withs', value)}
                onClear={() => methods.setValue('work_withs', [])}
              />
            )}
          />
          {errors?.work_withs && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.work_withs?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="textM">О себе</Typography>
          <Typography type="description">
            Напишите в свободной форме о себе.
          </Typography>
          <Typography type="description">
            Вы можете ответить на один из этих вопросов:
          </Typography>
          <Typography type="description">
            1. Почему вы решили стать психологом
          </Typography>
          <Typography type="description">
            2. Что для вас психотерапия
          </Typography>
          <Typography type="description">
            3. В какой методе вы работаете и почему его выбрали
          </Typography>
          <Controller
            control={control}
            name="about_yourself"
            render={({ field }) => (
              // TODO: fix textarea
              <TextArea {...field} rows={4} />
            )}
          />
        </div>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isMutating}
        >
          Сохранить
        </Button>
      </div>
      {contextHolder}
    </FormProvider>
  );
};

export default SpecialistEditProfileMainInfo;
