import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumbs, Typography } from '1_shared/ui';
import { Slots, SpecialistReserveSlot } from '3_features';

import { eduConfig } from '../../../1_shared/config/eduConfig';
import { format } from '../../../1_shared/config/format';
import { RoutePath } from '../../../1_shared/config/routes';

import { ISpecialistEntitiesCardProps } from './interfaces/ISpecialistEntitiesCardProps';

import styles from './SpecialistPersonCardData.module.scss';
import { declination, yearTitles } from '../../../1_shared/helpers/declination';

const SpecialistPersonCardData = ({
  specialist,
  onChange,
}: ISpecialistEntitiesCardProps): ReactElement => {
  const breadCrumbs = [
    {
      title: (
        <Link to={RoutePath.MAIN}>
          <Typography type="description">DOTHERAPY</Typography>
        </Link>
      ),
    },
    {
      title: (
        <Link to={RoutePath.SPECIALISTS}>
          <Typography type="description">Специалисты</Typography>
        </Link>
      ),
    },
  ];

  const specialization = specialist.specialities.filter(el => !!el).join(', ');
  const modals = specialist.workMethods.filter(el => !!el).join(', ');

  return (
    <div className={styles.root}>
      <div className={styles.nameAndSpec}>
        <div className={styles.specialistPersonInfo}>
          <div className={styles.nameBlock}>
            <Breadcrumbs items={breadCrumbs} />
            <Typography className={styles.firstName} type="title">
              {specialist.firstName}
            </Typography>
            <Typography className={styles.secondName} type="title">
              {specialist.secondName}
            </Typography>
          </div>
          <SpecialistReserveSlot
            specialistId={specialist.id}
            spec={specialist}
          />
        </div>
        <div className={styles.specWrapper}>
          <div className={styles.specFirstColumn}>
            <div className={styles.specFirstBlock}>
              <div className={styles.specLineItem}>
                <Typography type="description">ФОРМАТ</Typography>
                <Typography>{format[specialist.format]}</Typography>
              </div>
              <div className={styles.specLineItem}>
                <Typography type="description">ОПЫТ</Typography>
                <Typography>{`${specialist.experience} ${declination(Number(specialist.experience), yearTitles)}`}</Typography>
              </div>
            </div>
            <div className={styles.specLineItem}>
              <Typography type="description">ОБРАЗОВАНИЕ</Typography>
              <Typography>{eduConfig[specialist.educationType]}</Typography>
            </div>
          </div>
          <div className={styles.specSecondColumn}>
            <div className={styles.specLineItem}>
              <Typography type="description">СПЕЦИАЛИЗАЦИЯ</Typography>
              <div>
                {!specialization.length ? 'Не указана' : specialization}
              </div>
            </div>
            <div className={styles.specLineItem}>
              <Typography type="description">МОДАЛЬНОСТИ</Typography>
              <div>{!modals.length ? 'Не указаны' : modals}</div>
            </div>
          </div>
        </div>
        <Slots slots={specialist.slots} spec={specialist} onChange={onChange} />
      </div>
    </div>
  );
};

export default SpecialistPersonCardData;
