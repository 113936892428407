import DoTherapyLogo from '1_shared/assets/images/doTherapyLogo.png';
import ProfileUser from '1_shared/assets/images/UserProfile.svg';
import { IMediaContentById } from '1_shared/config/interfaces/IMediaContentById';
import { EMediaType } from '1_shared/config/enums/EMediaType';

export const configMedia: IMediaContentById = {
  id: '1',
  isDefault: true,
  mediaContentResponse: {
    isDefault: true,
    mediaType: EMediaType.Image,
    mainMediaLink: DoTherapyLogo,
    previewLink: DoTherapyLogo,
  },
};

export const configMediaClient: IMediaContentById = {
  id: '1',
  isDefault: true,
  mediaContentResponse: {
    isDefault: true,
    mediaType: EMediaType.Image,
    mainMediaLink: ProfileUser,
    previewLink: ProfileUser,
  },
};
