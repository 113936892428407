import {Link, useNavigate} from 'react-router-dom';
import cn from 'classnames';

import {RoutePath} from '1_shared/config/routes';
import {InfoSpecialist, SpecSpecialization} from '2_entities';
import {PreviewImage, Slots, SpecialistNavigate} from '3_features';

import {ISlot} from "../../../1_shared/config/interfaces/ISlot";
import {Typography} from '../../../1_shared/ui';
import SpecDescription from '../../../2_entities/SpecDescription/ui/SpecDescription';

import {configMedia} from './config/configMedia';
import {ISpecialistCardProps} from './interfaces/ISpecialistCardProps';

import styles from './SpecialistCard.module.scss';

const SpecialistCard = ({
  spec,
  dark = false,
  slicedBlock = false,
  className,
  showDescription = false,
}: ISpecialistCardProps): React.ReactElement => {
  const navigate = useNavigate();
  const onSubmit = (data?: ISlot) => {
    // @ts-ignore
    ym(97788822, 'reachGoal', 'booking');
    navigate(RoutePath.ORDER, {
      state: { id: spec.id, slot: data || spec.slots[0] },
    });
  };

  return (
    <div
      key={spec.id}
      className={cn(
        styles.root,
        { [styles.rootDark]: dark },
        slicedBlock ? styles.sliced : null,
        className,
      )}
    >
      <div className={styles.wrapperForm}>
        <PreviewImage
          className={styles.wrapperImage}
          media={spec.mediaContentResponse ?? configMedia}
          rootClassName={styles.img}
          showPreview
        />
        <Link className={styles.nameBlock} to={`specialist/${spec.id}`}>
          <Typography
            type="title"
            className={cn({ [styles.nameBlockDark]: dark })}
          >
            {spec.firstName}
          </Typography>
          <Typography
            type="title"
            className={cn({ [styles.nameBlockDark]: dark })}
          >
            {spec.secondName}
          </Typography>
        </Link>
        <InfoSpecialist
          dark={dark}
          classname={styles.wrapperInfo}
          specialist={spec}
        />
        <div className={styles.aboutSpec}>
          <SpecSpecialization
            dark={dark}
            className={styles.wrapperSpec}
            specialization={spec.specialities}
          />
          {showDescription && spec?.description && (
            <SpecDescription description={spec?.description} />
          )}
        </div>
        <Slots
          dark={dark}
          slots={spec.slots}
          spec={spec}
          className={styles.wrapperSlot}
          onChange={(changeValue) => {
            onSubmit(changeValue);
          }}
        />
        <SpecialistNavigate
          className={styles.wrapperBtns}
          id={spec.id}
          dark={dark}
          slots={spec.slots}
          handleReserve={onSubmit}
        />
      </div>
    </div>
  );
};

export default SpecialistCard;
