import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Radio, UploadFile, UploadProps } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';

import { phoneFormConvert } from '1_shared/lib/helpers/phoneConvert';
import {
  Button,
  DatePicker,
  Input,
  MaskInput,
  Typography,
  Upload,
} from '1_shared/ui';
import { CustomTypography } from '1_shared/ui/CustomTypography';

import { IUserInfo } from '../../../1_shared/api/interfaces/IUserInfo';
import { ESex } from '../../../1_shared/config/enums/ESex';
import { userInfoSchema } from '../../../1_shared/config/validationSchemas/userInfoSchema';
import {
  EPhotoType,
  uploadPhoto,
} from '../../../4_widgets/FirstFormTabs/ui/FirstFormTabs';

import styles from './SpecInfoForm.module.scss';
import { getMedia } from '../../../5_pages/SpecialistEditProfile/model/getMedia';
import { removeFileHandler } from '../../../5_pages/SpecialistEditProfile/model/removeFIleHandler';

interface SpecInfoFormProps {
  setActiveTab: (tab: string) => void;
}

const SpecInfoForm = ({
  setActiveTab,
}: {
  setActiveTab: any;
}): ReactElement<SpecInfoFormProps> => {
  const [messageApi, contextHolder] = useMessage();
  const {
    control: controlContext,
    reset: resetContext,
    getValues,
    setValue,
    watch,
  } = useFormContext();
  watch();

  const methods = useForm<IUserInfo>({
    mode: 'onChange',
    // @ts-ignore TODO: fix resolver
    resolver: yupResolver<IUserInfo>(userInfoSchema),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  const handleNext = (data: IUserInfo) => {
    const dataForm = getValues('data');

    resetContext({
      data: {
        ...dataForm,
        userInfo: {
          ...data,
        },
      },
    });
    setActiveTab('2');
  };

  const userInfo = getValues('data.userInfo');

  useEffect(() => {
    if (!userInfo) {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const phone = user.phone ? phoneFormConvert(user.phone) : '';
      const firstName = user.firstName || '';
      const surname = user.secondName || '';
      const patronymic = user.patronymic || '';
      reset({
        phone,
        firstName,
        surname,
        patronymic,
      });
    } else {
      reset({
        ...userInfo,
      });
    }
  }, [reset]);

  const uploadAvatar = async (file: RcFile, type = EPhotoType.AVATAR) => {
    try {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const response = user ? await uploadPhoto(file, user.id, type) : null;
      if (response) {
        setValue('data.mediaContentResponse', [
          ...(getValues('data.mediaContentResponse') ?? []),
          ...(type === EPhotoType.AVATAR ? [response] : response),
        ]);
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...user,
            mediaContentResponse: getValues('data.mediaContentResponse'),
          }),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadProps: UploadProps = useMemo(
    () => ({
      maxCount: 1,
      accept: '.png, .jpg, .jpeg, .webp',
      listType: 'picture-circle',
      fileList: getMedia(getValues('data.mediaContentResponse'), true),
      beforeUpload: file => uploadAvatar(file),
      onRemove: event =>
        removeFileHandler(
          event,
          getValues('data.mediaContentResponse'),
          setValue,
          'data.mediaContentResponse',
          messageApi,
        ),
    }),
    [getValues()],
  );

  return (
    <div className={styles.root}>
      <Typography type="title">Основная информация</Typography>
      <div className={styles.uploadWrapp}>
        <Typography type="description">Фото</Typography>
        <Controller
          control={controlContext}
          name="avatar"
          render={() => (
            <Upload
              {...uploadProps}
              customRequest={({ onSuccess }) => onSuccess && onSuccess('ok')}
            />
          )}
        />
      </div>
      <div className={styles.fioWrap}>
        <div>
          <Typography type="description">
            Фамилия<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="surname"
            control={control}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Фамилия" />
                {errors.surname && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.surname.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>

        <div>
          <Typography type="description">
            Имя<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Имя" />
                {errors.firstName && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.firstName.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>

        <div>
          <Typography type="description">Отчество</Typography>
          <Controller
            name="patronymic"
            control={control}
            render={({ field }) => <Input {...field} placeholder="Отчество" />}
          />
        </div>
      </div>

      <div className={styles.row}>
        <Typography type="description">
          Почта<span className={styles.required_asterisk}>*</span>
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <>
              <Input {...field} placeholder="e-mail" />
              {errors.email && (
                <CustomTypography type="description" className={styles.error}>
                  {errors.email.message}
                </CustomTypography>
              )}
            </>
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">
          Номер телефона<span className={styles.required_asterisk}>*</span>
        </Typography>
        <Controller
          name="phone"
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <MaskInput
                size="middle"
                value={value}
                autocomplete="on"
                onChange={e => {
                  const maskPhone = phoneFormConvert(e.target.value);
                  onChange(maskPhone);
                }}
                mask="+79999999999"
                placeholder="Телефон"
              />
              {errors.phone && (
                <CustomTypography type="description" className={styles.error}>
                  {errors.phone.message}
                </CustomTypography>
              )}
            </>
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">
          Пол<span className={styles.required_asterisk}>*</span>
        </Typography>
        <Controller
          name="sex"
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <Radio.Group
                value={value}
                onChange={onChange}
                className={styles.sexGroup}
              >
                <Radio value={ESex.Male}>Мужской</Radio>
                <Radio value={ESex.Female}>Женский</Radio>
              </Radio.Group>

              {errors.sex && (
                <CustomTypography type="description" className={styles.error}>
                  {errors.sex.message}
                </CustomTypography>
              )}
            </>
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">
          Дата рождения<span className={styles.required_asterisk}>*</span>
        </Typography>
        <Controller
          name="birthday"
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <DatePicker
                value={value ? dayjs(value) : null}
                onChange={value =>
                  onChange(value ? dayjs(value).format('YYYY-MM-DD') : null)
                }
              />
              {errors.birthday && (
                <CustomTypography type="description" className={styles.error}>
                  {errors.birthday.message}
                </CustomTypography>
              )}
            </>
          )}
        />
      </div>
      <div className={styles.uploadWrapp}>
        <Typography type="description">Медиа файлы</Typography>
        <Controller
          control={controlContext}
          name="mediaFiles"
          render={() => (
            <Upload
              className="mediaWrapp"
              accept=".png, .jpg, .jpeg, .webp"
              fileList={getMedia(getValues('data.mediaContentResponse'))}
              customRequest={({ onSuccess }) => onSuccess && onSuccess('ok')}
              listType="picture-card"
              onRemove={event =>
                removeFileHandler(
                  event,
                  getValues('data.mediaContentResponse'),
                  setValue,
                  'data.mediaContentResponse',
                  messageApi,
                )
              }
              beforeUpload={file => uploadAvatar(file, EPhotoType.FILE)}
            />
          )}
        />
      </div>
      <Button
        className={styles.btnNext}
        type="primary"
        onClick={handleSubmit(handleNext)}
        disabled={!isValid}
      >
        ДАЛЕЕ
      </Button>
      {contextHolder}
    </div>
  );
};

export default SpecInfoForm;
